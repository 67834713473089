.btn {
  &.mat-mdc-raised-button,
  &.mat-mdc-button {
    display: inline-block;
    min-width: 100px;
    padding: 0 24px 0;
    border-radius: 3px;
    font-size: 14px;
  }

  &.mat-mdc-raised-button {
    background: $blue;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
    color: #fff !important;

    &:not(:disabled) {
      background: $blue;
    }

    &:not([class*=mat-elevation-z]) {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
    }

    &:hover {
      box-shadow: 0 2px 3px rgba(0, 0, 0, .17);
    }

    &.btn-green {
      background: $green;
    }

    &.btn-red {
      background: $red;
    }

    .mat-mdc-text-field-wrapper {
      color: $white;
      font: {
        size: 14px;
        weight: 600;
      }
    }
  }

  &.mat-mdc-outlined-button {
    &.btn-blue {
      color: $blue;
      border-color: $blue;
    }
  }

  &.mat-mdc-button {
    .mat-ripple,
    .mat-button-focus-overlay {
      display: none;
    }

    &:hover {
      background: none;

      .mat-mdc-text-field-wrapper {
        text-decoration: underline;
      }
    }

    .mat-mdc-text-field-wrapper {
      color: $blue;
      font: {
        size: 14px;
        weight: 600;
      }
    }

    &.btn-green {
      .mat-mdc-text-field-wrapper {
        color: $green;
      }
    }

    &.btn-red {
      .mdc-button__label {
        color: $red;
      }
    }
  }

  &[disabled] {
    box-shadow: none !important;
    opacity: .3;
  }

  &.btn-outline {
    background: none !important;
    border: 1px solid $blue;
    box-shadow: none !important;
    line-height: 35px;

    .mdc-button__label {
      color: $blue;
    }

    .mat-mdc-text-field-wrapper {
      color: $blue;
    }

    &.btn-green {
      border: 1px solid $green;

      .mat-mdc-text-field-wrapper {
        color: $green;
      }
    }

    &.btn-red {
      border: 1px solid $red;

      .mat-mdc-text-field-wrapper {
        color: $red;
      }
    }

    &.btn-blue {
      border: 1px solid $blue;

      .mat-mdc-text-field-wrapper {
        color: $blue;
      }
    }
  }

  &.btn-link {
    &:hover {
      .mat-mdc-text-field-wrapper {
        text-decoration: underline;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-ripple,
    .mat-button-focus-overlay {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      font: {
        size: 14px !important;
      }
    }
  }
}


.cancel-btn.btn-red {
  background: none;
  border: 1px solid #dd5d5d !important;
  box-shadow: none;
  padding: 0 15px !important;
  float: right;
}

.agreement-active {
  background-color: $blue !important;
  color: white !important;
}

.agreement-inactive {
  background-color: white !important;
  color: #555555 !important;
}

.mat-button-toggle-group {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .14) !important;
  .mat-button-toggle {
    color: #555;

    &.mat-button-toggle-checked {
      background: #EFF7FA;
      color: $blue;
    }

    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        height: 40px;
        padding: 0 32px;
        line-height: 40px;
        font-size: 13px;
      }
    }
  }
}
