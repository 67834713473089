.cdk-overlay-container {
  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  .cdk-overlay-pane {
    height: auto;
    max-height: 100vh;
    justify-content: flex-end;
    margin-top: 7px;
    background: transparent;
    width: auto !important;
    min-width: 600px;

    .mat-mdc-dialog-container {
      padding: 0 !important;
      border-radius: 2px;
      overflow: hidden !important;
      background-color: white;
      --mat-dialog-transition-duration: 0 !important;

      .mat-mdc-dialog-title {
        font-size: 18px !important;
        border-bottom: 1px solid #eee;
        height: auto;
        line-height: 1;
        color: $black;
        padding: 20px 24px;
        margin: 0;
      }

      .mdc-dialog__title {
        &::before {
          height: auto;
          content: none;
        }

        line-height: 100% !important;
      }

      .mat-mdc-dialog-content,
      .mat-mdc-dialog-actions {
        padding: 20px !important;
        margin: 0;
      }

      .mat-mdc-dialog-content {
        flex-grow: 1;
        overflow-x: hidden;
      }

      .mat-mdc-dialog-actions {
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        height: 81px;
        position: relative;
        z-index: 3;
        box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.04);
        border-top: 1px solid #eee;
      }

      .form-field-label {
        letter-spacing: 0;
      }

      .mat-mdc-dialog-surface {
        background: transparent !important;
      }
    }
  }

  .mobile-modal {
    width: 40%;
  }

}

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 2px;
  overflow: hidden !important;

  .mat-mdc-dialog-title {
    font-size: 18px;
    padding: 0 20px 16px;
    border-bottom: 1px solid #eee;
    margin: 0 -20px 20px;
    line-height: 1;
    color: $black;
  }

  .mat-mdc-dialog-content,
  .mat-mdc-dialog-actions {
    padding: 20px;
    margin: 0;
  }

  mat-mdc-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.cdk-virtual-scroll-content-wrapper {
  position: relative !important;
}

.dz-image {
  display: none !important;
}

.dz-size {
  display: none !important;
}

.dz-message {
  pointer-events: none;
}

.dz-filename {
  display: none !important;
}

.dz-success-mark {
  display: none !important;
}

.dz-error-mark {
  display: none !important;
}

.dz-error-message {
  display: none !important;
}

.dz-progress {
  display: none !important;
}

.mat-dialog-container {
  background: white;
}


