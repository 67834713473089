.content-wrapper {
  .page-title {
    margin: 0;
    font-size: 28px;

    .job-detail-id {
      font-weight: 600;
    }
  }
}
