// ========= Base Styles ========== //

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font: {
    family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif;
    size: 15px;
  }
  line-height: 1.4;
  color: $black;

  .site-container {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    background: $off-white;
  }
}

span {
  letter-spacing: normal;
}

img {
  max-width: 100%;
  height: auto;
}

.watermark-image-foreground {
  max-width: unset !important;
}
