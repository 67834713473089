// ========= Form Styles ========== //
.mat-mdc-select-panel {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 3px;
}

.form-field {
  margin: 0 0 20px;

  &.form-field-select {
    .form-field-label {
      display: block;
      margin-bottom: 6px;
      color: $grey;
      font-size: 15px;
    }

    .mat-mdc-form-field {
      width: 100%;
      height: 40px;

      .mat-mdc-text-field-wrapper {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(213, 213, 213);
        padding-left: 16px;
        padding-right: 36px;

        &.mdc-text-field {
          border-radius: 4px !important;;
        }

        .mat-mdc-form-field-flex {
          display: flex;
          align-items: center;
          height: 40px !important;
          line-height: 40px;

          &:after {
            content: url('/assets/icons/arrow-down.svg');
            position: absolute;
            right: 12px;
            top: 0;
            transition: .2s;
          }

          .mat-mdc-form-field-infix {
            min-height: unset;
            display: flex;
            padding: 0;

            .mat-mdc-select {
              display: flex;

              .mat-mdc-select-trigger {
                display: flex;

                .mat-mdc-select-value {
                  display: flex;
                  max-width: unset;

                  .mat-mdc-select-value-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 180px;
                    display: inline-block;
                    line-height: 100%;
                  }

                  .mat-mdc-select-placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.87);
                  }
                }

                .mat-mdc-select-arrow-wrapper {
                  display: none;
                }
              }
            }
          }

          .mdc-notched-outline {
            display: none;
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        padding: 0;
        position: relative;
        margin: 0;
        line-height: 1;

        .mat-mdc-form-field-error-wrapper {
          padding: 0 !important;

          .mat-mdc-form-field-error {
            padding: 0 !important;
            letter-spacing: normal;
          }
        }

        &[ng-reflect-ng-switch="hint"] {
          display: none;
        }
      }
    }

    .mat-mdc-form-field-error-wrapper {
      padding: 0 !important;

      .mat-mdc-form-field-error {
        letter-spacing: normal;
        display: flex;
      }
    }
  }

  &.form-field-search {
    margin: 0;

    .mat-mdc-form-field {
      display: flex;
      flex-direction: row;
      align-items: center;

      .mat-mdc-text-field-wrapper {
        height: 40px;
        margin-bottom: 0;
        font-size: 14px;
        padding: 0;

        .mat-mdc-form-field-flex {
          padding: 0 18px;
          font-size: 14px;
          border: 1px solid rgba(68, 68, 68, .1);
          border-radius: 50px;
          background: #ffffff;

          .mat-mdc-form-field-infix {
            display: flex;
            align-items: center;
            min-height: 40px;
            height: 40px;
            padding: 0;
          }

          .mdc-notched-outline {
            display: none;
          }
        }
      }
    }

    #search-input-bar {
      height: auto;
    }
  }

  &.form-field-input {
    .mat-mdc-form-field {
      display: block;

      .mat-mdc-text-field-wrapper {
        padding: 0;
        margin: 0;

        .mat-mdc-form-field-flex {
          height: 42px;
          line-height: 40px;
          padding: 0 14px 0 14px;
          margin-top: 0;
          background: $white;
          border: 1px solid #d5d5d5;
          border-radius: 3px;

          .mdc-notched-outline {
            display: none;
          }

          .mat-mdc-form-field-infix {
            display: flex;
            align-items: center;
            padding: 0 !important;
            border-top: 0;
            min-height: 40px;

            .mat-mdc-input-element,
            .mat-mdc-select {
              font-size: 14px;
              letter-spacing: normal;
            }
          }
        }

        .mdc-line-ripple {
          display: none !important;
        }

        .mat-mdc-form-field-focus-overlay {
          display: none;
        }

        &.mdc-text-field {
          border-radius: 4px !important;;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        padding: 0;
        position: relative;
        margin: 0;
        line-height: 1;

        .mat-mdc-form-field-error-wrapper {
          padding: 0 !important;

          .mat-mdc-form-field-error {
            padding: 0 !important;
            letter-spacing: normal;
          }
        }

        &[ng-reflect-ng-switch="hint"] {
          display: none;
        }
      }

      &.ng-invalid {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border-color: $red;
            box-shadow: 0 0 0 1px $red;
          }
        }
      }

      &.ng-untouched {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border-color: $border;
            box-shadow: none;
          }
        }

        &.mat-focused {
          .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
              border-color: $blue;
              box-shadow: 0 0 0 1px $blue;
            }
          }
        }
      }

      &.ng-touched {
        border-color: #d5d5d5 !important;
      }

      &.mat-focused {
        .mat-mdc-text-field-wrapper {
          background-color: white !important;

          .mat-mdc-form-field-flex {
            border-color: $blue;
            box-shadow: 0 0 0 1px $blue;

            .mat-mdc-input-element {
              caret-color: rgb(3, 155, 229) !important;
            }
          }
        }

        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }

      &[disabled] {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background: $off-white;
          }
        }
      }
    }

    .mat-mdc-form-field-error-wrapper {
      padding: 0 !important;

      .mat-mdc-form-field-error {
        letter-spacing: normal;
        display: flex;
      }
    }

    &.finals-count-field {
      .mat-mdc-form-field {
        .mat-mdc-form-field-subscript-wrapper {
          display: block;

          .mat-mdc-form-field-error-wrapper {
            padding: 0 !important;

            .mat-mdc-form-field-error {
              letter-spacing: normal;
            }
          }

          .mat-mdc-form-field-hint-wrapper {
            padding: 0;

            .mat-mdc-form-field-hint {
              letter-spacing: normal;
            }
          }

          &[ng-reflect-ng-switch="hint"] {
            display: inherit;
            padding: 0;
          }
        }
      }
    }

    &.form-field-input-chips {
      .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            min-height: 48px;
            height: auto;
            padding: 0 8px;
            line-height: 45px;

            .mat-mdc-chip {
              background: rgba(47, 155, 191, 0.1);
            }

            .mat-mdc-form-field-infix {
              //noinspection ALL
              .mat-mdc-chip-set {
                width: -webkit-fill-available;

                .mdc-evolution-chip-set__chips {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }

  &.form-field-slider {
    .mat-mdc-slider {
      height: 24px;

      .mdc-slider__track {
        .mdc-slider__track--active,
        .mdc-slider__track--inactive {
          height: 2px;
        }
      }
    }

    .control-label {
      padding-left: 8px
    }
  }

  &.form-field-checkbox {
    .mat-mdc-checkbox {
      .mdc-form-field {
        .mdc-checkbox {
          padding: 0 !important;
          margin-right: 6px;

          .mat-ripple {
            display: none !important;
          }

          .mdc-label {
            padding: 0;
          }

          .mdc-checkbox__background {
            display: flex;
            border-color: rgba(0, 0, 0, 0.54);
            background-color: rgba(0, 0, 0, 0);
            top: unset;
            left: unset;

            .mdc-checkbox__checkmark {
              color: white;
            }
          }
        }
      }

      &.mat-mdc-checkbox-checked {
        .mdc-form-field {
          .mdc-checkbox {
            .mdc-checkbox__background {
              display: flex;
              background-color: #2f9bbf !important;
              border-color: #2f9bbf !important;
            }
          }
        }

      }

      .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
        background-color: white;
        border-color: rgba(0, 0, 0, 0.54);
      }

      .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
        border-color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  &.form-field-textarea {
    margin: 0;

    .mat-mdc-form-field {
      display: block;
      height: auto;
      border: 1px solid #d5d5d5;
      border-radius: 3px;

      .mat-mdc-text-field-wrapper {
        height: auto;
        padding: 1px;
        margin: 0;
        background: $white;

        .mat-mdc-form-field-flex {
          padding: 12px 14px;
          margin-top: 0;
          background: $white;
          background: white;
          height: auto;
          line-height: unset;

          .mat-mdc-form-field-infix {
            height: 100%;
            padding: 0;
            border-top: 0;

            textarea {
              height: 90px;
              line-height: 1.4;
            }

            .mat-mdc-input-element,
            .mat-mdc-select {
              letter-spacing: 0 !important;
            }
          }
        }

        &.mdc-text-field {
          border-radius: 4px !important;
        }

        &.mdc-text-field--focused {
          background: white;
        }

        .mdc-line-ripple {
          display: none !important;
        }

        .mdc-notched-outline {
          display: none;
        }

        .mat-form-field-underline {
          display: none;
        }
      }

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      .mat-mdc-form-field-subscript-wrapper {
        padding: 0;
        position: relative;
        margin: 0;
        line-height: 1;

        .mat-mdc-form-field-error-wrapper {
          padding: 0 !important;

          .mat-mdc-form-field-error {
            letter-spacing: normal;
          }
        }

        &[ng-reflect-ng-switch="hint"] {
          display: none;
        }
      }

      &.rejection-description-textarea {
        .mat-mdc-form-field-flex {
          padding: 12px 14px;

          .mat-mdc-input-element {
            min-height: 75px !important;
            max-height: 75px !important;
          }

          .mat-mdc-form-field-infix {
            padding: 0;

            .mat-mdc-input-element {
              font-size: 14px;
              letter-spacing: normal;
            }
          }
        }
      }

      &.ng-untouched {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border-color: $border;
            box-shadow: none;
          }
        }
      }

      &.ng-touched {
        border-color: #d5d5d5;
      }

      &.ng-invalid {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border-color: $red;
            box-shadow: 0 0 0 1px $red;
          }
        }
      }

      &.mat-focused {
        border-color: $blue;
      }

      &[disabled] {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background: $off-white;
          }
        }
      }
    }
  }

  &.no-margin-field {
    margin: 0;

    .mat-mdc-text-field-wrapper {
      margin: 0;
    }
  }

  &.fill-field {
    .mat-mdc-form-field {
      height: 100%;

      .mat-mdc-text-field-wrapper {
        height: 100%;
        margin: 0;

        .mat-mdc-form-field-flex {
          height: 100%;
          font-size: 14px;
          line-height: 1.4;

          .mat-mdc-form-field {
            height: 100%;

            .mat-mdc-input-element {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .form-field-label {
    display: block;
    margin-bottom: 6px;
    color: $grey;
    font-size: 15px;
  }

  mat-label {
    color: $black;
  }
}

.mat-mdc-select-panel {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 3px;
  padding: {
    top: 0;
    bottom: 0;
  }
}

.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
}

.mdc-slider__thumb-knob {
  width: 12px !important;
  height: 12px !important;
  border-width: 0 !important;
}

.mdc-slider .mdc-slider__thumb {
  display: flex !important;
  left: -12px !important;
  outline: none !important;
  position: absolute !important;
  user-select: none !important;
  height: 24px !important;
  width: 24px !important;
  bottom: 2px !important;

  .mat-ripple {
    display: none;
  }
}

.mdc-slider {
  input {
    height: 24px;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: #2f9bbf !important;
  opacity: 1;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background-color: rgba(0, 0, 0, .65) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: rgba(47, 155, 191, .54) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: rgba(0, 0, 0, .38) !important;
}

.mat-mdc-form-field-error {
  letter-spacing: normal !important;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}
