.mat-mdc-table {
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .mat-mdc-header-row {
    height: 58px;

    .mat-mdc-header-cell {
      padding-top: 2px;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;

      .mat-sort-header-button {
        text-transform: uppercase;
      }

      font: {
        size: 12px;
        weight: 500;
      }
    }
  }

  .mat-mdc-row {
    height: 56px;

    &:last-of-type {
      .mat-mdc-cell {
        border-bottom: none;
      }
    }

    .mat-mdc-cell {
      color: $grey;
      font-size: 14px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;

      &.mat-column-invoiced {
        color: $blue;
        font-weight: 600;
      }
    }
  }
}

.mat-mdc-paginator {
  margin-top: 8px;
  background: none;
}


.btn-column-header {
  max-width: 120px !important;
  text-align: center;
}

.btn-column-data {
  max-width: 120px !important;
}

td.mat-mdc-cell.cdk-cell.cdk-column-image.mat-column-image {
  width: 120px;
}

td.mat-mdc-cell.cdk-cell.cdk-column-video.mat-column-video {
  width: 120px;
}

td.mat-mdc-cell.cdk-cell.cdk-column-floorplan.mat-column-floorplan {
  width: 120px;
}

td.mat-mdc-cell.cdk-cell.cdk-column-active.mat-column-active {
  width: 120px;
}

.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-checkbox {

      .mat-ripple {
        display: none !important;
      }

      .mdc-label {
        padding: 0;
      }

      .mdc-checkbox__background {
        .mdc-checkbox__checkmark {
          color: white !important;
        }
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          background-color: #2f9bbf !important;
          border-color: #2f9bbf !important;
        }
      }
    }

  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    background-color: white !important;
    border-color: rgba(0, 0, 0, 0.54);
  }

  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.54);
  }
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: #2f9bbf !important;
  opacity: 1;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background-color: rgba(0, 0, 0, .65) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: rgba(47, 155, 191, .54) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: rgba(0, 0, 0, .38) !important;
}

.mdc-checkbox__ripple {
  display: none !important;
}
